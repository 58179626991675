<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;

      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
        role="dialog"
      >
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              id="modal-headline"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              {{ $t("policy-update") }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {{ $t("weve-updated-following-policies-please-review-them") }}
              </p>
            </div>
            <div>
              <ul
                class="text-sm leading-5 text-gray-600 font-normal w-1/2 m-auto text-left mt-6"
              >
                <li
                  v-for="(item, index) in get_profile.terms_and_conditions"
                  :key="index"
                  class="flex"
                >
                  <svg
                    class="mr-2 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ item.name }}
                  (<a
                    :href="item.link"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                    target="_blank"
                    >{{ $t("link") }}</a
                  >)
                </li>
              </ul>
            </div>
            <div class="rounded-md bg-yellow-50 p-4 mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: exclamation -->
                  <svg
                    class="h-5 w-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3
                    class="text-sm leading-5 font-medium text-yellow-800 text-left"
                  >
                    {{ $t("attention-needed") }}
                  </h3>
                  <div class="mt-2 text-sm leading-5 text-yellow-700">
                    <p>
                      {{
                        $t(
                          "please-agree-to-our-policies-in-order-to-continue-using-our-service"
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span
            v-if="get_profile.revoke_access_on_consent_disagree"
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-2"
          >
            <button
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="logout"
            >
              {{ $t("disagree-and-logout") }}
            </button>
          </span>
          <span
            v-else
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-2"
          >
            <button
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="consent(false)"
            >
              {{ $t("Disagree") }}
            </button>
          </span>
          <span class="flex w-full rounded-md shadow-sm sm:col-start-1">
            <button
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="consent(true)"
            >
              {{ $t("Agree") }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Consents",
  computed: {
    ...mapGetters(["get_profile"]),
  },
  created() {},
  methods: {
    consent(answer) {
      const data = {
        agree: answer,
        documents: this.get_profile.terms_and_conditions.map((item) => item.id),
      };
      this.$store.dispatch("consents", data).then(() => {
        this.$emit("close");
        this.$store.commit("SET_PROOF_OF_ID_MODAL", true);
      });
    },
    logout() {
      const data = {
        agree: false,
        documents: this.get_profile.terms_and_conditions.map((item) => item.id),
      };
      this.$store.dispatch("consents", data).then(() => {
        this.$emit("close");
      });
      this.$store.dispatch("logout").then(() => {
        if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
          const url = process.env.VUE_APP_API_URL.includes("dev.")
            ? "https://dev-website.anttix.app/auth/login"
            : "https://vcgmarkets.com/auth/login";
          return window.open(url, "_self");
        }
        this.$router.push("/auth/login");
      });
    },
  },
};
</script>
