<template>
  <div v-click-outside="hide" class="grid grid-cols-1 row-gap-6 col-gap-4">
    <div
      v-if="sideLabel"
      class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pt-5 sm:pb-5"
    >
      <label
        class="block text-sm font-normal leading-5 text-gray-700 sm:mt-px"
        for="listbox-label2"
      >
        {{ label }}
      </label>
      <div class="mt-1 sm:col-span-2">
        <div :class="classes" class="rounded-md shadow-sm">
          <div class="relative">
            <span class="inline-block w-full rounded-md shadow-sm">
              <button
                aria-expanded="true"
                aria-haspopup="listbox"
                aria-labelledby="listbox-label"
                class="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                type="button"
                @click="showMenu = !showMenu"
              >
                <span class="block truncate">
                  {{ selectedOption }}
                </span>
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <svg
                    class="h-5 w-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                </span>
              </button>
            </span>
            <!-- Select popover, show/hide based on select state. -->
            <div
              v-if="showMenu"
              class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
            >
              <ul
                aria-activedescendant="listbox-item-0"
                aria-labelledby="listbox-label"
                class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                role="listbox"
                tabindex="-1"
              >
                <li
                  v-for="option in options"
                  v-if="option.display_name && !currencies"
                  id="listbox-item-10"
                  class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                  role="option"
                  @click="
                    selectedOption = option.display_name;
                    showMenu = false;
                    setSelected(option.value);
                  "
                >
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal block truncate">
                    {{ option.display_name }}
                  </span>
                  <span
                    v-if="option.display_name === selectedOption"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                  >
                    <svg
                      class="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                </li>

                <li
                  v-for="option in options"
                  v-else
                  id="listbox-item-2"
                  class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                  role="option"
                  @click="
                    selectedOption = option.symbol;
                    showMenu = false;
                    setSelected(option);
                  "
                >
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal block truncate">
                    {{ option.symbol }}
                  </span>
                  <span
                    v-if="option.symbol === selectedOption"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                  >
                    <svg
                      class="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else :class="setMargin ? setMargin : ''" class="sm:col-span-4">
      <div class="space-y-1">
        <label
          id="listbox-label2"
          :class="setMargin ? setMargin : ''"
          class="block text-sm leading-5 font-normal text-gray-700"
        >
          {{ label }}
        </label>
        <div :class="classes" class="relative">
          <span class="inline-block w-full rounded-md shadow-sm">
            <button
              aria-expanded="true"
              aria-haspopup="listbox"
              aria-labelledby="listbox-label"
              class="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="showMenu = !showMenu"
            >
              <span class="block truncate">
                {{ selectedOption }}
              </span>
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </span>
            </button>
          </span>
          <!-- Select popover, show/hide based on select state. -->
          <div
            v-if="showMenu"
            class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
          >
            <ul
              aria-activedescendant="listbox-item-0"
              aria-labelledby="listbox-label"
              class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              role="listbox"
              tabindex="-1"
            >
              <li
                v-for="option in options"
                v-if="option.display_name"
                id="listbox-item-0"
                class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                role="option"
                @click="
                  selectedOption = option.display_name;
                  showMenu = false;
                  setSelected(option.value);
                "
              >
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="font-normal block truncate">
                  {{ option.display_name }}
                </span>
                <span
                  v-if="option.display_name === selectedOption"
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
              <li
                v-for="option in options"
                v-if="platforms"
                id="listbox-item-9"
                class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                role="option"
                @click="
                  selectedOption = option.backend.display_name;
                  showMenu = false;
                  setSelected(option.backend.name);
                "
              >
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="font-normal block truncate">
                  {{ option.backend.display_name }}
                </span>
                <span
                  v-if="option.backend.display_name === selectedOption"
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
              <li
                v-for="option in options"
                v-if="currencies"
                id="listbox-item-90"
                class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                role="option"
                @click="
                  selectedOption = option;
                  showMenu = false;
                  setSelected(option);
                "
              >
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="font-normal block truncate">
                  {{ option }}
                </span>
                <span
                  v-if="option === selectedOption"
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
              <li
                v-for="option in options"
                v-if="!currencies && !platforms && !option.display_name"
                id="listbox-item-1"
                class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:bg-gray-50 cursor-pointer"
                role="option"
                @click="
                  selectedOption = option.symbol;
                  showMenu = false;
                  setSelected(option.symbol);
                "
              >
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="font-normal block truncate">
                  {{ option.symbol }}
                </span>
                <span
                  v-if="option.symbol === selectedOption"
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "SelectMenu",
  data() {
    return {
      showMenu: false,
      selectedOption: "Select an option",
      selected: {},
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    value: {},
    setMargin: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    sideLabel: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    classes: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    currencies: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    platforms: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
  },
  methods: {
    setSelected(item) {
      this.selected = item;
    },

    hide() {
      this.showMenu = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
