<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        aria-hidden="true"
        class="fixed inset-0 transition-opacity"
        @click="closeModal"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;

      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block relative align-bottom justify-center px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
        role="dialog"
        style="background-color: #fff"
      >
        <div v-if="showLimitedMessage">
          Congrats your account has been opened, in Limited state.
        </div>
        <div v-else>
          Unfortunately, we were unable to verify your details. Additional
          checks are required, kindly refer to the instructions provided in the
          account summary.
        </div>

        <div
          class="h-7 flex items-center absolute top-2 right-2"
          @click="closeModal"
        >
          <button
            aria-label="Close panel"
            class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
          >
            <!-- Heroicon name: x -->
            <svg
              class="h-5 w-5"
              fill="none"
              stroke="#000"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6l12 12"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "StatusChangeMessageModal",
  data() {
    return {
      showLimitedMessage: true,
    };
  },
  computed: {
    ...mapGetters(["get_profile"]),
  },
  created() {
    if (this.get_profile.state === "Limited") {
      this.showLimitedMessage = true;
    } else {
      this.showLimitedMessage = false;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("SET_STATUS_CHANGE_MESSAGE_MODAL", false);
      this.$emit("closeStatusChangeMessageModal", "false");
    },
  },
};
</script>
