<template>
  <div>
    <div class="rounded-md bg-yellow-50 sm:p-5 mt-8 mx-8">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: exclamation -->
          <svg
            class="h-5 w-5 text-yellow-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3
            v-if="showTitle"
            class="text-sm leading-5 font-normal text-yellow-800"
          >
            {{
              whitelabel.includes("ICM")
                ? "Attention valued client,"
                : $t("attention-needed")
            }}
          </h3>

          <div
            class="text-sm leading-5 text-yellow-700 flex"
            :class="showTitle && 'mt-2'"
          >
            <p :class="link && 'mr-1'">
              {{ $t(alertMessage) }}
            </p>
            <RouterLink v-if="link" :to="link" class="underline">
              {{ linkText }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertMessage",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  props: {
    alertMessage: {
      type: String,
      required: true,
      default: () => {
        return "";
      },
    },
    showTitle: {
      type: Boolean,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    linkText: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>
